
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$learnerVision-primary: mat-palette($mat-indigo);
$learnerVision-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$learnerVision-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$learnerVision-theme: mat-light-theme((
  color: (
    primary: $learnerVision-primary,
    accent: $learnerVision-accent,
    warn: $learnerVision-warn,
  )
));

.mat-flat-button {
  background-color: #EB613B !important;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($learnerVision-theme);

/* You can add global styles to this file, and also import other style files */


html, body { height: 100%; }
body { margin: 0; font-family: basic-sans, sans-serif; background-color: #DDDDDD; }

h2 {
  color: #444446;
}

.mat-list-base {
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px !important;
}

.button-link {
  cursor: pointer;
}

.submit-button {
  margin-top:20px;
}

.button-margin {
  margin-top: 20px;
}

.hello {
 width: 100%;
 text-align: center;
 margin-top: 30px;
}

@media (max-width: 900px) {
  .centre-card {
      top: 5%;
      left: 4%;
      width: 300px !important;
  }

  .hello {
      margin-top: 3px;
   }

  .button-margin {
      margin-top: 2px !important;
  }

  .submit-button {
      margin-top:10px !important;
  }

  mat-button-toggle-group {
      margin-bottom: 10px !important;
  }

  h1 {
      margin-bottom: 5px !important;
  }

  :host .mat-form-field-wrapper, :host .mat-form-field-flex {
      padding-bottom: 4px !important;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .centre-card  {
      top: 15%;
      left: 28%;
  }
}

@media (min-width: 1200px) {
  .centre-card  {
      top: 37%;
      left: 38%;
  }
}
.centre-card {
  // width: 400px;
  // position: fixed; /* or absolute */

}

.example-full-width {
  width: 80%;
}

mat-button-toggle-group {
  margin-bottom: 20px;
}

.btn-link {
  border-color: transparent;
  border-radius: 0;
  background-color: transparent;
}
.btn-link:hover {
  cursor: pointer;
  text-decoration: underline;
}


:host .mat-flat-button {
  border-radius: 1px !important;
  min-height: 37px;
  min-width: 100px;
}

h1 {
    text-align: center;
}


/* .mat-icon {
  font-family: 'Material Icons' !important;
} */


.grey {
  color: gray !important;
}
